import "./src/css/fonts/inter.css";
import "./src/css/fonts/hanken-grotesk.css";

import "./src/cube-css/promo.css";
import { DispatchGtagEvent } from "./src/google/Gtag";

export const onServiceWorkerUpdateReady = () => window.location.reload();

const BRANCH = process.env.BRANCH ?? "(master)";
export const onRouteUpdate = ({ location }) => {
  const pagePath = location ? location.pathname + location.search + location.hash : undefined;
  DispatchGtagEvent(
    "page_view", {
      "page_path": pagePath,
      "branch": BRANCH
    }
  );
};
