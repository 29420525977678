export const DispatchGtagEvent = (eventName: string, data: object): void => {
  if (process.env.NODE_ENV !== "production") {
    return;
  }

  setTimeout(() => {
    if (typeof gtag === "function") {
      gtag("event", eventName, { ...data });
    }
  }, 100);
};
